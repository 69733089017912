<template lang="pug">
.NoDate.Container(:style="isAtril ? 'color: #545454; max-width: 1800px; width: 100%; margin: auto; height: calc(100vh - 70px); min-height: 700px;' : 'margin: auto; width: 100%; font-size: 1.3rem;'")
  .Content
    div(:style="'position: relative;width: 700px;' + currentSite.ignoreAuth ? 'height: 100px;' : 'height: 124px;'")
      span.text-center.mt-2.h.dk-pos-dk(:class="isAtril ? 'h1' : ''" :style="isAtril && `font-weight: bold !important; top: ${currentSite.ignoreAuth ? '8%' : '22%'} !important;`") {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
    div.box-dk(:style="currentSite.ignoreAuth && 'margin-top: 0'")
      span.text-center.mt-4(:style="isAtril && 'font-size: 2rem;'") Cuál es el motivo de tu asistencia:
    .buttons-dk(:style="isAtril ? 'margin-top: 80px; max-width: 500px; margin: auto;' : 'margin: 30px auto;'")
      template(v-for="act in actionToShow")
        b-button(
          v-if="!!!act.hidden"
          :key="act.actionCode"
          variant="bluecolmedica"
          :disabled="!!act.disabled"
          block
          @click="calculateAction(act)"
          :class="isAtril ? 'p-2 mb-3 atril_text' : 'atril-mini'"
          :style="act.style + ' ' + colorComputed + ' ' + `background-color: ${primaryColorBlue};`"
          v-html="act.label"
        ).text-center.mb-4
  .buttons.Footer(:style="colorSecondaryComputed")
    .d-flex.justify-content-between
      b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="BackCalculed" :style="colorComputed")
        Back(size="24px")
        span(class="text-sizing-12") Atrás
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";

export default {
  name: "NoDate",

  components: {
    Header,
    Back
  },

  data: () => ({
    parentList: [],
    actionToShow: []
  }),

  created() {
    this.setLocalActions(this.menu);
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      menu: state => state.virtualrow.menu,
      currentSite: state => state.virtualrow.currentSite,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      secondColorBlue: state => state.virtualrow.secondColorBlue
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    siteName() {
      return this.currentSite?.name;
    }
  },
  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      setAction: "virtualrow/setAction"
    }),

    setLocalActions(actions) {
      actions = JSON.parse(JSON.stringify(actions));
      for (let i = 0; i < actions.length; i++) {
        if (actions[i].hidden && typeof actions[i].hidden === "object") {
          actions[i].hidden =
            (actions[i].hidden.mobile && !this.isAtril) ||
            (actions[i].hidden.atril && this.isAtril);
        }
      }
      this.actionToShow = actions;
    },

    calculateAction(act) {
      if (this.isAtril) this.$store.dispatch("setTimer");
      if (act?.children) {
        this.parentList.push(this.actionToShow);
        return this.setLocalActions(act.children);
      }
      this.setAction(act);
    },

    BackCalculed() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      if (this.parentList.length) {
        this.setLocalActions(this.parentList.pop());
      } else this.goBack();
    }
  }
};
</script>

<style scoped>
.atril_text {
  font-size: 2rem;
}
.atril-mini {
  font-size: 1.3rem;
}
.NoDate {
  position: relative;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.NoDate > div {
  display: table-cell;
  vertical-align: middle;
}

.box-dk {
  max-width: 365px;
  margin: 1.4rem auto;
  text-align: center;
  display: block;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
  width: 100%;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  max-width: 296px;
  text-align: center;
  width: 100%;
  min-height: 20rem;
}

svg[fill="currentColor"] {
  width: 50px;
  height: 50px;
  /* fill: gray; */
}

button svg[fill="currentColor"] {
  /* fill: white; */
  width: auto;
  height: auto;
}

.dk-pos-dk {
  position: absolute;
  top: -30%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.h1 {
  font-size: 2.8rem;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -10%;
  }
}
</style>
