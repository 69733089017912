<template lang="pug">
  .Quote(@click="validationAction" :style="isAtril ? 'height: calc(100vh - 0px); min-height: 768px; width: 100%;' : 'width: 100%;'")
    //- No se puede más grande en lo alto debido al scroll
    div
      div(:style="isAtril ? 'position: relative; height: 66px; margin-top: 23px; margin-bottom: 23px;' : 'position: relative; height: 48px; margin-top: 51px;'")
        span.text-center.mt-2.h.dk-pos-dk(:class="isAtril ? 'h1 mt-4' : ''" :style="isAtril && 'font-weight: bold !important; font-size: 2.8rem; top: 2%;'") {{ siteIsOffice ? "Oficina" : "Centro Médico" }} {{ siteName }}
      p(v-if="citasEnCM.length > 0").text-center.mt-4(:style="isAtril && 'font-size: 2rem; margin: 0 !important;'") ¿Vienes para esta cita?
      p(v-if="citasFueraCM.length" :style="isAtril ? 'margin-bottom: 5rem; margin-top: 1rem;' : 'margin-top: 0.5rem;'").text-center.mt-4
        small.text-center.mt-1.dk-link(@click="showModalOthers = true" :style="isAtril && 'font-size: 2rem; font-weight: bold;'") Ver citas programadas en otros Centros Médicos:
      div(:class="isAtril ? 'cards-dk' : 'cards'" v-if="citasEnCM && citasEnCM.length")
        .big_box.mt-4(
          :style="isAtril ? 'position: relative; min-height: 160px; min-width: 600px;' : ''"
        )
          .dk-overlay
            //- h5(:style="isAtril ? 'font-weight: bold !important; font-size: 2rem;' : 'font-size: 1.3rem; font-weight: bold;'") Cita {{ citasEnCM[0].idCita }}.
            p(:style="isAtril ? 'font-weight: bold !important; font-size: 2rem;' : 'font-size: 1.3rem; font-weight: bold;'") Especialidad: {{ citasEnCM[0].Especialidad }}
            p.m-0(:style="isAtril ? 'font-weight: bold !important; font-size: 2rem;' : 'font-size: 1.3rem; font-weight: bold;'") Fecha: {{ citaHora }}
            //- p.m-0: small(:style="isAtril ? 'font-weight: bold !important; font-size: 2rem;' : 'font-size: 1.3rem; font-weight: bold;'") {{ citasEnCM[0].Fecha }}
      .d-block.text-center.d-flex.mt-2(style="flex-direction: column; margin: auto; max-width: 370px;")
        
        b-button(
          :disabled="!!charging"
          v-if="citasEnCM.length > 0"
          variant="bluecolmedica"
          class="text-center"
          size="sm"
          type="button"
          @click="selectCita(citasEnCM[0])"
          :style="isAtril ? `font-size: 1.7rem !important; ${colorComputed} display: block;` : `${colorComputed} display: block;`"
        ).ml-2.mb-2
          span(v-if="!!!charging" :style="isAtril ? `font-size: 1.7rem !important;` : `font-size: 1.3rem !important;`") Si, continuar con esta cita
          span(v-else)
            MiniSpinner
        b-button(
          variant="bluecolmedica"
          class="text-center"
          size="sm"
          type="button"
          @click="goToView({ view: 'NoDate' })"
          :style="isAtril ? `font-size: 1.7rem !important; ${colorComputed}` : colorComputed"
        ).ml-2
          span(:style="isAtril ? `font-size: 1.7rem !important;` : `font-size: 1.3rem !important;`") No, continuar con otro servicio
      p(style="font-style: italic; margin-top: 2rem;").text-center {{ computedText }}
      b-modal(
        v-model="showModalOthers",
        hide-footer
      )
        h6.text-center(style="color: gray;" :style="isAtril && 'font-size: 1.4rem;'") Estas citas son de otros Centros Médicos
        .cards(style="padding-bottom: 100px")
          .big_box(v-for="cita in citasFueraCM", :key="cita.idCita").mb-1
            .dk-overlay
              h5(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Cita {{ cita.idCita }}.
              h6(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Nombre del centro médico: {{ cita.NombreCentroMedico }}
              p(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Especialidad: {{ cita.Especialidad }}
              p.m-0(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Fecha: {{ cita.Fecha }}
              //- p.m-0(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'"): small(style="font-weight: bold !important;") {{ cita.Fecha }}
      .buttons(:style="colorSecondaryComputed")
        .d-flex.justify-content-between
          b-button(
            variant="bluecolmedica"
            class="text-center"
            size="sm"
            type="button"
            :style="colorComputed"
            @click="goBack"
          )
            Back(size="24px")
            span(class="text-sizing-12") Atrás
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  
  import Header from "./components/Header";
  import Back from "mdi-vue/ArrowLeftBold.vue";
  import Continue from "mdi-vue/ArrowRightBold.vue";
  import AlertCircle from "mdi-vue/AlertCircle";
  import MiniSpinner from "../components/MiniSpinner.vue";
  
  export default {
    name: "Quote",
  
    components: {
      Header,
      Back,
      Continue,
      AlertCircle,
      MiniSpinner,
    },
  
    data() {
      return {
        showModal: false,
        showModalOthers: false
        // infoDir: "",
        // infoName: ""
      };
    },
  
    created() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    },
  
    computed: {
      ...mapState({
        currentSite: state => state.virtualrow.currentSite,
        citas: state => state.virtualrow.citas,
        isAtril: state => state.virtualrow.isAtril,
        primaryColorBlue: state => state.virtualrow.primaryColorBlue,
        seocndColorBlue: state => state.virtualrow.seocndColorBlue,
        threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
        charging: state => state.virtualrow.charging,
      }),
  
      citaHora() {
        //TODO: Por ahora esto no const dateCita = new Date(this.citasEnCM[0].Fecha).getHours();
        //TODO: Esta es forma de solo poner hora const splitFecha = this.citasEnCM[0].Fecha.split(":");
        // console.log(splitFecha);
        // const result = `${splitFecha[0].slice(-2)}:${splitFecha[1]}`;
        return this.citasEnCM[0].Fecha;
      },
  
      computedText() {
        return this.citasEnCM.length >= 3
          ? `
          Para tus siguientes citas programadas, recuerda tomar turno antes de la hora de la cita
        `
          : this.citasEnCM.length == 2
          ? ` Para tu siguiente cita programada, recuerda tomar turno antes de la hora de la cita`
          : "";
      },
  
      colorSecondaryComputed() {
        return `background-color: ${this.secondColorBlue};`;
      },
  
      principalText() {
        return this.citasEnCM.length
          ? this.citasEnCM.length === 1
            ? `Esta es la atención que tienes programada hoy en el Centro Médico ${this
                .currentSite?.name ??
                ""}.<span style="display: block; margin-top: 0.5rem;">Selecciona una o continúa sin cita</span>`
            : `Estas son las citas que tienes programadas para hoy en el Centro Médico ${this
                .currentSite?.name ??
                ""}. <span style="display: block; margin-top: 0.5rem;">Selecciona una o continúa sin cita</span>`
          : `No tienes citas programadas para hoy en el Centro Médico ${this
              .currentSite?.name ?? ""}`;
      },
  
      colorComputed() {
        return `white-space: no-wrap; background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
      },
  
      citasEnCM() {
        return this.citas.filter(cita => cita.EnCentroMedico);
      },
  
      citasFueraCM() {
        return this.citas.filter(cita => !cita.EnCentroMedico);
      },
  
      siteName() {
        return this.currentSite?.name;
      },
  
      siteIsOffice() {
        return this.currentSite?.type === "of";
      }
    },
  
    methods: {
      ...mapActions({
        setCitaCM: "virtualrow/setCitaCM",
        goBack: "virtualrow/goBack",
        goToView: "virtualrow/goToView",
        removeTimer: "removeTimer",
        setTimer: "setTimer"
      }),
      validationAction() {
        if (this.isAtril) this.$store.dispatch("setTimer");
      },
      async selectCita(cita) {
        /* eslint-disable */
        if (this.isAtril) await this.removeTimer();
        await new Promise(async r => {
          cita.r = r;
          this.setCitaCM(cita);
        }) 
        if (this.isAtril) await this.setTimer();
        /* eslint-enable */
      }
    }
  };
  </script>
<style scoped>
.Quote {
  height: calc(100vh - 70px);
  overflow: auto;
  display: table;
}

.Quote > div {
  display: table-cell;
  vertical-align: middle;
}

.box-dk {
  max-width: 488px;
  margin: 1.4rem auto;
  text-align: center;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.icon-alert {
  color: #4b5c6b;
  margin: 15px auto;
  display: block;
  max-width: 50px;
  text-align: center;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  max-width: 296px;
  display: block;
  margin: 3rem auto;
  text-align: center;
  min-height: 20rem;
}

.span svg {
  width: 50px;
  height: 50px;
  fill: gray;
}

.big_box {
  position: relative;
  scroll-snap-align: center;
}

.big_box::-webkit-scrollbar {
  display: none;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-link {
  cursor: pointer;
  outline: none;
  border: none;
  appearance: none;
  font-weight: 400;
  font-size: 14.3743px;
  line-height: 24px;
  /* identical to box height, or 164% */

  text-align: center;
  text-decoration-line: underline;

  /* TEXT/Títulos, sub títulos, body. */

  color: #283645;
}

.dk-link:hover {
  text-decoration: underline;
}

.cards-dk {
  display: flex;
  justify-content: center;
  margin: auto;
  /* max-width: 834px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  position: relative;
  min-height: 290px; */
}

.dk-pos-dk {
  position: absolute;
  top: -90%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.dk-overlay {
  text-align: start;
  width: 432.46px;
  border: none;
  background: #f9f9f9;
  border-radius: 22.0625px;
  filter: drop-shadow(0px 4.10693px 8.21386px rgba(21, 21, 21, 0.08));
}

.dk-overlay .specialPG {
  font-style: normal;
  font-weight: 400;
  font-size: 16.4277px;
  line-height: 24px;
  color: #081e3d;
  margin: 0;
}

.buttonService {
  width: 100%;
  max-width: 300.83px;
  padding: 1rem 2rem;
  background-color: #009dac;
  border-radius: 16.4277px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
}

.buttonService:disabled {
  background-color: #788591;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.boxQuote {
  width: 100%;
  max-width: 432.46px;
}

.selectedSchedule {
  border: 1px solid #a1d23f;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .pos_dk {
    position: relative;
    width: 100%;
    right: 0;
    bottom: 0;
  }
}
</style>
