<template lang="pug">
  .Normativity.Container(@click="actionActive" :style="isAtril ? 'margin: auto; width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 70px);' : 'width: 100%; font-size: 1.3rem;'")
    .Content
      div(:style="isAtril ? 'position: relative; margin: auto; margin-top: 72px; margin-bottom: 33px; height: 37px; max-width: 701px; width: 700px;' : 'position: relative; height: 48px; margin-top: 20px;'")
        span.text-center.mt-2.h.dk-pos-dk(:class="isAtril ? 'h1 isAtril' : ''" :style="isAtril && 'font-weight: bold !important; font-size: 2.8rem; width: 100%;'") {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
      //- article.Article(v-if="siteIsOffice")
      //-   p(style="isAtril ? 'color: #545454;' : 'color: gray;'") Por favor selecciona si requieres turno preferencial
      //-   .pb-3.pt-3.d-flex.flex-wrap.justify-content-center(:style="isAtril ? 'max-width: 800px;' : 'min-width: 298px;'")
      //-     figure(@click="turnCall(true)" style="font-size: 16px;").mb-1
      //-       img(src="../../assets/cane.png" alt="cane").image
      //-       figcaption(v-if="currentSite.type === 'of'" style="color: #00599d;")
      //-     figure(@click="turnCall(true)" style="font-size: 16px;").mb-1.ml-1
      //-       img(src="../../assets/pregment.png" alt="pregment").image
      //-       figcaption(v-if="currentSite.type === 'of'" style="color: #00599d;")
      //-     figure(@click="turnCall(true)" style="font-size: 16px;").mb-1
      //-       img(src="../../assets/invalido.png" alt="invalido").image
      //-       figcaption(v-if="currentSite.type === 'of'" style="color: #00599d;")
      //-   .d-flex.justify-content-around.pt-3(style="max-width: 265px; margin: auto;")
      //-     b-button(variant="bluecolmedica" @click="turnCall(false)" :style="isAtril ? 'font-size: 1.3rem; margin: auto; display: block;' : 'margin: auto; display: block;'").mb-5 No aplico para turno preferencial
      article.Article(:style="!isAtril ? 'color: #545454; margin-top: 40px;' : 'color: #545454; margin-top: 40px; max-width: 460px;font-size: 21px;'")
        p.text-center.mb-2.mt-2.mr-2.ml-2 Atención Preferencial:
        ul(style="list-style: none;text-align: start;margin: 0;").mr-2.ml-2
          li(style="display:flex;padding: 10px 0 10px 0px;")
            img(src="../../assets/pregnant_woman.png").size_icon.mx-auto
            .pad Mujeres embarazadas o con niños en brazos.
          li(style="display:flex;padding: 10px 0 10px 0px;")
            img(src="../../assets/olders.png").size_icon.mx-auto 
            .pad Hombres y mujeres mayores de 60 años.
          li(style="display:flex;padding: 10px 0 10px 0px;") 
            img(src="../../assets/wheelchair.png").size_icon.mx-auto
            .pad Personas en condición de discapacidad o con dificultad para movilizarse
        p.mb-2.mt-2.text-left.mr-2.ml-2 tienes derecho a:
        div(style=`
          padding-bottom: 20px;
          margin-top: 1.3rem;
        `)
          b-button(:style="colorComputed" variant="bluecolmedica" class="text-center" size="sm" type="button" @click="turnCall(true)" block)
            span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Tomar turno preferencial
          b-button(v-if="!siteAlianSalud" variant="bluecolmedica" class="text-center" size="sm" type="button" @click="turnCall(false)" block :style="`margin-top: 20px; ${colorComputed}`")
            span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Continuar sin turno preferencial
          b-button(v-else variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goBack" block :style="`margin-top: 20px; ${colorComputed}`")
            span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Voler al menu principal
        p(style=`
          line-height: 1;
          font-style: italic;
          margin-top: 0.1rem;
          text-align: center;
        `).mr-2.ml-2
          small(:style="isAtril ? 'font-size: 73%; max-width: 345px; text-align: center; display: block; margin: 0.5rem auto; margin-bottom: 2rem;font-weight: bolder;' : ''")
            | Entre todos debemos proteger, promover, respetar y defender los derechos de esta población (Circular única 004 de la Superintendencia Nacional de Salud)
    .buttons.Footer(:style="colorSecondaryComputed")
      .d-flex.justify-content-between
        b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goBack" :style="isAtril ? `font-size: 1.5rem !important; ${colorComputed}` : colorComputed")
          Back(size="24px")
          span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Atrás
</template>

<script>
import { mapActions, mapState } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import WheelBarrow from "mdi-vue/WheelchairAccessibility";

export default {
  name: "Normativity",

  components: {
    Header,
    Back,
    WheelBarrow
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  // mounted(){
  //   this.$store.dispatch("removeTimer");
  // },

  computed: {
    ...mapState({
      previousView: state => state.virtualrow.previousView,
      isAtril: state => state.virtualrow.isAtril,
      currentSite: state => state.virtualrow.currentSite,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      secondColorBlue: state => state.virtualrow.secondColorBlue
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    siteAlianSalud(){
      return this.currentSite?.name.includes('Aliansalud')
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      turnCall: "virtualrow/turnCall"
    }),

    dontCallToEmergency() {
      this.priority = false;
    },

    actionActive() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    }
  }
};
</script>

<style>
.Normativity {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.Normativity > div {
  display: table-cell;
  vertical-align: middle;
}

.Article {
  max-width: 300px;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.blue-dk {
  color: #00599d;
}

.image {
  width: 100%;
  object-fit: cover;
}

.dk-pos-dk.isAtril {
  top: -90%;
}
.dk-pos-dk {
  position: absolute;
  left: 0px;
  transition: 1s;
  right: 0px;
}
.size_icon{
  height: 60px;
}
.pad{
  padding-left: 13px;
  margin-right: 95px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .image {
    width: 50%;
  }
}
</style>
