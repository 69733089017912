<template lang="pug">
.CurrentUser.Container(:style="isAtril && `width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 70px);`")
  .Content
    div.box-dk
      span.text-center(:class="isAtril ? 'h1' : ''" :style="isAtril ? 'max-width: 300px;' : ''" v-if="!isAtril") Bienvenido {{ siteIsOffice ? "a la Oficina" : "al Centro Médico" }} {{ siteName }}
      strong.text-center.mt-2.h1.bolder(:class="isAtril ? 'h1' : ''" v-else)  {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
      p.text-center.mb-2(v-if="!!!currentUser" :style="isAtril ? 'margin-top: 1rem; font-size: 2rem;' : 'margin-top: 4rem;'") Por favor ingresa tu documento:
    form#validateUser(@submit.prevent="sendData", autocomplete="off", v-if="!!!currentUser" :style="isAtril ? 'margin-top: 30px;' : 'padding-bottom: 100px;'")
      div(style="max-width: 690px; margin: auto; display: block;")
        b-form-select.Input-text.mb-4(
          v-model="models.TipoIdentificacion",
          :options="options",
          :style="isAtril ? 'font-size: 2.2rem !important; width: 447px !important; margin: auto !important; background-color: rgb(189 189 189) !important; font-weight: bold !important; color: rgb(0 0 0) !important;' : 'font-size: 1.5rem !important; max-width: 350px !important; margin: 60px auto !important; background-color: rgb(189 189 189) !important; font-weight: bold !important; color: rgb(0 0 0) !important;'"
          size="sm",
          autocomplete="off",
          required,
        )
        input.Input-text.mt-4(
          type="number",
          v-model.number="models.NumeroIdentificacion",
          min="0"
          placeholder="Número de identificación",
          :style="isAtril ? 'font-size: 2.2rem !important; max-width: 447px !important; margin: 28px auto !important; background-color: rgb(189 189 189) !important; font-weight: bold !important; color: rgb(0 0 0) !important;' : 'font-size: 1.5rem !important; max-width: 350px !important; margin: 40px auto !important; background-color: rgb(189 189 189) !important; font-weight: bold !important; color: rgb(0 0 0) !important;'"
          required,
          autocomplete="off",
          :disabled="isAtril"
        )#cedula_number
    .mb-2(v-if="isAtril")
      Panel
        .item-panel.bigbutton(@click="printPanel(1)" :style="colorComputed + 'border-color: white;'") 1
        .item-panel.bigbutton(@click="printPanel(2)" :style="colorComputed + 'border-color: white;'") 2
        .item-panel.bigbutton(@click="printPanel(3)" :style="colorComputed + 'border-color: white;'") 3
        .item-panel.bigbutton(@click="printPanel(4)" :style="colorComputed + 'border-color: white;'") 4
        .item-panel.bigbutton(@click="printPanel(5)" :style="colorComputed + 'border-color: white;'") 5
        //- 347px
        .item-panel.bigbutton(@click="printPanel(6)" :style="colorComputed + 'border-color: white;'") 6
        .item-panel.bigbutton(@click="printPanel(7)" :style="colorComputed + 'border-color: white;'") 7
        .item-panel.bigbutton(@click="printPanel(8)" :style="colorComputed + 'border-color: white;'") 8
        .item-panel.bigbutton(@click="printPanel(9)" :style="colorComputed + 'border-color: white;'") 9
        //- .item-panel(@click="printPanel('<')" style="background: #3a8ac0; color: white;") Borrar
        .item-panel.bigbutton(@click="printPanel('<')" :style="colorComputed + 'border-color: white;'") Borrar
        .item-panel.bigbutton(@click="printPanel(0)" :style="colorComputed + 'border-color: white;'") 0
        .item-panel.bigbutton(
          :class="!models.TipoIdentificacion || !models.NumeroIdentificacion || charging ? 'disabled' : 'active'"
          @click="printPanel('Ok')"
          :style="colorComputed + 'border-color: white;'"
        )
          span(v-if="!!!charging") Ok
          div(v-else)
            MiniSpinner
    .pb-5.mt-3(v-if="!!currentUser")
      .pb-5
        .big_box
          .dk-overlay
            p(v-if="currentUser.Nombres") {{ currentUser.Nombres }}
            p.pb-5 Documento: {{ currentUser.TipoIdentificacion }} {{ currentUser.NumeroIdentificacion }}
          b-button.pos_dk(variant="bluecolmedica" :style="colorComputed" @click="continueWithExistent" :disabled="!!charging")
            div(v-if="!!!charging")
              span Continuar con este usuario
              span
                Continue(size="24px")
            div(v-else)
              MiniSpinner
  .buttons.Footer(:style="colorSecondaryComputed")
    #d-flex.d-flex.align-items-center(:class="!!currentUser ? 'justify-content-center' : 'justify-content-end'" v-if="!isAtril")
      b-button.text-center(
        v-if="!!!currentUser"
        variant="bluecolmedica",
        :style="colorComputed"
        form="validateUser"
        size="sm",
        type="submit",
        :disabled="!this.newUserReady || !!charging"
      )
        span(v-if="!!!charging")
          span.text-sizing-12 Continuar
          Continue(size="24px")
        div(v-else)
          MiniSpinner
      
      b-button.text-center(
        v-else
        :disabled="!!charging"
        variant="bluecolmedica",
        size="sm",
        @click="cleanCurrentUser"
        type="button"
        :style="colorComputed"
      )
        span.text-sizing-12 Continuar con otro usuario
        Continue(size="24px")
    #d-flex.d-flex.align-items-start(v-else)
      b-button.text-center(
        @click="goBack",
        variant="bluecolmedica",
        :style="colorComputed"
        size="sm",
        type="submit",
      )
        Atras(size="24px")
        span.text-sizing-12 Atrás
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";
import Panel from "./components/Panel";
import Atras from "mdi-vue/ArrowLeftBold";

export default {
  name: "Home",
  components: {
    Header,
    Continue,
    MiniSpinner,
    Panel,
    Atras
  },

  created() {
    if (this.isAtril) {
      this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
    }
  },

  data: () => ({
    myForm: "",
    captchaElement: null,
    isCapcha: true,
    models: {
      NumeroIdentificacion: "",
      TipoIdentificacion: "CC"
    }
  }),

  watch: {
    "models.TipoIdentificacion"(val) {
      if (val) {
        if (this.isAtril) {
          this.$store.dispatch("setTimer");
        }
      }
    }
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrow.currentSite,
      options: state => state.virtualrow.tipide,
      currentUser: state => state.virtualrow.currentUser,
      citas: state => state.virtualrow.citas,
      charging: state => state.virtualrow.charging,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      error: state => state.virtualrow.error
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    toSend() {
      return { ...this.models, CodigoOficina: this.$route.params.code };
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    newUserReady() {
      return !!(
        this.models.NumeroIdentificacion && this.models.TipoIdentificacion
      );
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack",
      authenticate: "virtualrow/authenticate",
      cleanCurrentUser: "virtualrow/cleanCurrentUser",
      removeTimer: "removeTimer",
      setTimer: "setTimer",
      sendTracking: "virtualrow/handleTracking"
    }),
    continueWithExistent() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.models.TipoIdentificacion = this.currentUser.TipoIdentificacion;
      this.models.NumeroIdentificacion = this.currentUser.NumeroIdentificacion;
      this.sendData();
    },
    printPanel(key) {
      if (this.isAtril) {
        this.$store.dispatch("setTimer");
      }
      if (key === "<") {
        this.models.NumeroIdentificacion = this.models.NumeroIdentificacion.substring(
          0,
          this.models.NumeroIdentificacion.length - 1
        );
        return;
      }
      if (key == "Ok") {
        if (
          !this.models.NumeroIdentificacion ||
          !this.models.TipoIdentificacion
        )
          return;
        return this.sendData();
      }
      this.models.NumeroIdentificacion += key;
    },
    async sendData() {
      document.getElementById("cedula_number")?.blur();
      if (this.isAtril) await this.removeTimer();
      await this.authenticate(this.toSend);
      if (this.isAtril) await this.setTimer();
      
      // tracking
      setTimeout(() => {
        let origin = 'fv_';
        if (location.hash.includes("fv_atril")) origin = 'fv_atril_';
        let auth = this.error ? ' autentitacion-no-cliente' : 'autenticacion-cliente';
        let trackingData  = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: '',
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ''
        }
        
        this.sendTracking(trackingData);
      }, 2000);

    }
  }
};
</script>

<style scoped>
.CurrentUser {
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.CurrentUser > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
}

.box-dk {
  margin: auto;
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 12300000;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  /* color: #fff; */
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

#d-flex span svg {
  width: 20px;
  height: 20px;
  /* fill: white; */
}

.big_box {
  position: relative;
}

.dk-link {
  outline: none;
  border: none;
  appearance: none;
  text-decoration: none;
  background: none;
  /* color: #6565; */
  color: #6558f5;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin: 93px auto;
}

.dk-link:hover {
  text-decoration: underline;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.disabled {
  cursor: not-allowed;
  background: #0058a0a4;
}

.disabled:hover {
  background: #0058a0cb;
}

.dk-pos-dk {
  position: absolute;
  top: 30%;
  left: 0px;
  font-weight: bold;
  transition: 1s;
  right: 0px;
}

.Input-text::placeholder {
  color: rgb(0 0 0) !important;
}

.h1 {
  font-size: 2.8rem;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
